import React from 'react';
import Header from '../../components/Header/Header_home_dash';
import './SearchResultsPage.css';
import Footer from '../../components/Footer/Footer';
import SearchResults from '../../components/SearchResults/SearchResults';
import SearchBar from '../../components/SearchBar/SearchBar';
import Sage from '../../components/Sage/Sage';
import { useLocation } from 'react-router-dom';

const SearchResultsPage = () => {
    const location = useLocation();
    const results = location.state?.results || [];
/*
    const results = [
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db859',
            query: 'which site is most visited in europe',
            file_name: 'file.pdf',
            results: 'Louvre',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 1,
                    end_page_num: 3,
                    doc_text: 'which site is most visited in europe',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 1,
                    end_page_num: 1,
                    doc_text: 'Some other which site is most visited in europe',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        {
            searchID: '4f79ca49-83d5-4d2d-b2d3-86c46a4db860',
            query: 'which site is most popular in europe',
            file_name: 'file1.pdf',
            results: 'NaN',
            raw_results: {
                '0': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
                '1': {
                    score: 'NaN',
                    file: 'NaN',
                    start_page_num: 'NaN',
                    end_page_num: 'NaN',
                    doc_text: 'NaN',
                },
            },
        },
        // Additional result objects...
    ];
*/
    return (
        <div className="searchresultspage-container">
            <div className="searchresults-solid-bar-frame">
                <div className="searchresults-dash-header-container">
                    <Header />
                </div>
                <div className="searchresults-dash-sage-container">
                    <Sage />
                </div>
                <div className="searchresults-dash-search-container">
                    <SearchBar />
                    <hr className="searchresults-horizontal-line" />
                </div>
            </div>
            <div className="searcharea-container">
                
                <div className="searchresults-dashboard-frame">
                    <SearchResults results={results} />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SearchResultsPage;