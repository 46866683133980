import React, { useState, useEffect } from 'react';
import './PasswordReset.css'
import PopupOverlay from '../../components/PopupOverlay/PopupOverlay';
import { requestBody, serverURL, wsURL, genericServerError } from '../utils'
import { useNavigate } from 'react-router-dom';
import { useSessionCookie } from '../../components/CookieChecker/CookieChecker';


const PasswordReset = () => {
    const [email, setEmail] = useState('');
    const [resetCode, setResetCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [step, setStep] = useState(1);
    const [showOverlay, setShowOverlay] = useState(true);
    const [overlayText, setOverlayText] = useState('');
    const [overlayColor, setOverlayColor] = useState('');
    const navigate = useNavigate();
    const { clearSessionCookie } = useSessionCookie();

    useEffect(() => {
        clearOverlay();
    }, []);

    const clearOverlay = () => {
        setOverlayText("");
        setOverlayColor("");
        setShowOverlay(false);
    }

    const handleCloseOverlay = () => {
        clearOverlay();
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleEmailSubmit = async (e) => {
        // Perform email validation or API call for sending reset code
        // Once done, proceed to the next step
        if (email.trim() === "") {
            const text = "Please enter your email address.";
            setOverlayText(text);
            setOverlayColor('red');
            setShowOverlay(true);
            return;
        }
        if (!validateEmail(email)) {
            const text = "Please enter a valid email address.";
            setOverlayText(text);
            setOverlayColor('red');
            setShowOverlay(true);
            return;
        }

        // Send a POST request to resend OTP
        const request = new requestBody();
        request.email = email;
        request.account_action = "updateotp"
        try {
            const response = await fetch(`${serverURL}/accountaction`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });
            if (!response.ok) {
                // Handle error response
                console.log("Got !response.ok")
                const errorMessage = await response.text();
                setOverlayText(genericServerError);
                setOverlayColor("red");
                setShowOverlay(true);
                console.log(errorMessage);
            } else {
                // check OTP verify
                const data = await response.json();
                console.log(data)
                if (data == "success") {
                    setResetCode("");
                    const text = "6-digit password reset code is sent to your email."
                    setOverlayText(text);
                    setOverlayColor('green');
                    setShowOverlay(true);
                    setStep(2);
                } else {
                    setOverlayText(genericServerError);
                    setOverlayColor("red");
                    setShowOverlay(true);
                }
            }
        } catch (error) {
            // Handle network or other errors
            console.log("verifyotp error path")
            console.error('Error:', error);
            setOverlayText(genericServerError);
            setOverlayColor("red");
            setShowOverlay(true);
        }
    };

    const handleCodeVerify = async (e) => {
        // Handle code activation logic here
        if (resetCode.length < 6) {
            // Display error message for code length
            setOverlayText("Reset Code must be 6 digits");
            setOverlayColor('red');
            setShowOverlay(true);
            return;
        }
        console.log('Code:', email, resetCode);
        // Send a POST request to the verify OTP
        const request = new requestBody();
        request.otp = resetCode;
        request.email = email
        try {
            const response = await fetch(`${serverURL}/verifyotp`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });
            if (!response.ok) {
                // Handle error response
                console.log("Got !response.ok")
                const errorMessage = await response.text();
                setOverlayText(genericServerError);
                setOverlayColor("red");
                setShowOverlay(true);
                console.log(errorMessage);
            } else {
                // check OTP verify
                const data = await response.json();
                console.log(data)
                if (data == "Yes") {
                    clearOverlay();
                    setStep(3);
                } else if (data == "Expired") {
                    setOverlayText("OTP Expired");
                    setOverlayColor("red");
                    setShowOverlay(true);
                } else {
                    setOverlayText("OTP verification failed");
                    setOverlayColor("red");
                    setShowOverlay(true);
                }
            }
        } catch (error) {
            // Handle network or other errors
            console.log("verifyotp error path")
            console.error('Error:', error);
            setOverlayText(genericServerError);
            setOverlayColor("red");
            setShowOverlay(true);
        }
        setResetCode("");
    };

    const validatePassword = (password) => {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
        return passwordPattern.test(password);
    };

    const handlePasswordSubmit = async (e) => {
        // Perform password update or API call
        if (!validatePassword(newPassword)) {
            const text = "Password must be at least 8 characters and have an uppercase, digit, and special character."
            setOverlayText(text);
            setOverlayColor('red');
            setShowOverlay(true);
            return;
        }
        // Send a POST request to update password
        const request = new requestBody();
        request.email = email;
        request.resetpassword = newPassword;
        request.account_action = "updatepassword";
        try {
            const response = await fetch(`${serverURL}/accountaction`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });
            if (!response.ok) {
                // Handle error response
                console.log("Got !response.ok")
                const errorMessage = await response.text();
                setOverlayText(genericServerError);
                setOverlayColor("red");
                setShowOverlay(true);
                console.log(errorMessage);
            } else {
                const data = await response.json();
                console.log(data)
                if (data == "success") {
                    setResetCode("");
                    setNewPassword("");
                    clearSessionCookie();
                    navigate("/signin", { state: { message: "Password reset successfully" } });
                } else {
                    setOverlayText(genericServerError);
                    setOverlayColor("red");
                    setShowOverlay(true);
                }
            }
        } catch (error) {
            // Handle network or other errors
            console.log("password reset error path")
            console.error('Error:', error);
            setOverlayText(genericServerError);
            setOverlayColor("red");
            setShowOverlay(true);
        }
    };

    const handleCodeChange = (event) => {
        const inputValue = event.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9]/g, '');
        setResetCode(sanitizedValue);
    };

    const handlePasswordChange = (event) => {
        setNewPassword(event.target.value);
    }

    return (
        <div>
            {step === 1 && (
                <div className="passresetContainer">
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleEmailSubmit();
                            }
                        }}
                        placeholder="Enter your registered email"
                        className='passresetText'
                    />

                    <button className="passresetButton" onClick={handleEmailSubmit}>Send reset code</button>
                </div>
            )}
            {step === 2 && (
                <div className="passresetContainer">
                    <input
                        type="text"
                        maxLength={6}
                        value={resetCode}
                        onChange={handleCodeChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleCodeVerify();
                            }
                        }}
                        className='passresetOTP'
                    />
                    <button className="passresetButton" onClick={handleCodeVerify}>Enter password reset code</button>
                </div>
            )}
            {step === 3 && (
                <div className="passresetContainer">
                    <input
                        type="password"
                        value={newPassword}
                        onChange={handlePasswordChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handlePasswordSubmit();
                            }
                        }}
                        placeholder="Enter new password"
                    />
                    <button className="passresetButton" onClick={handlePasswordSubmit}>Submit</button>
                </div>
            )}
            {showOverlay && (
                <PopupOverlay text={overlayText} overlayColor={overlayColor} onClose={handleCloseOverlay} />
            )}
        </div>
    );
};

export default PasswordReset;