import React, { useState, useEffect } from 'react';
import './Header.css';
import uLogo from './ulogo.png'
import { useSessionCookie } from '../CookieChecker/CookieChecker';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import PhoneInput from 'react-phone-number-input';
import { requestBody, serverURL, wsURL, genericServerError } from '../utils'


const Header = ({ ignoreSession }) => {
  const { getSessionUUID, clearSessionCookie } = useSessionCookie();
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userSession, setUserSession] = useState('');
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [userinfo, setUserinfo] = useState('');
  const [email, setEmail] = useState('');
  const [currphone, setCurrphone] = useState('');
  const [showHeader, setshowHeader] = useState(true);


  useEffect(() => {
    const uuid_from_session = getSessionUUID();
    if (uuid_from_session === "") {
      if (!ignoreSession) {
        console.log("No UUID found from session", uuid_from_session);
        navigate('/signin');
      } else {
        setshowHeader(false);
      }
    } else {
      console.log("Found UUID from session", uuid_from_session);
      setUserSession(uuid_from_session);
    }
    console.log("UUID", userSession);
    getuserinfo();
  }, [ignoreSession]);

  const getuserinfo = async () => {
    const request = new requestBody();
    request.uuid = getSessionUUID();

    console.log("Sending uuid for getuserinfo", userSession);

    // Send a POST request to the accountaction API
    try {
      const response = await fetch(`${serverURL}/getuserinfo`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });
      if (!response.ok) {
        // Handle error response
        console.log("Got getuserinfo error")
        const errorMessage = await response.text();
        console.log(errorMessage);
      } else {
        const data = await response.json();
        console.log(data)
        setUserinfo(data)
        const get_email = data.find(userinfo => userinfo[0] === 'email');
        const emailValue = get_email ? get_email[1] : null;
        setEmail(emailValue);
        console.log(email);
        const get_ph = data.find(userinfo => userinfo[0] === 'phone_number');
        const phValue = get_ph ? get_ph[1] : null;
        setCurrphone(phValue);
        console.log(currphone);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleProfileClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleAccountClick = () => {
    console.log("handling Account click")
    setShowModal(true);
    //setDropdownOpen(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentPassword('');
    setNewPassword('');
    setPhoneNumber('');
    // Remove the overlay message if it exists
    const overlayMessage = document.querySelector('.overlay-message');
    if (overlayMessage) {
      overlayMessage.remove();
    }
    setIsDeletingAccount(false);
  };

  const validatePassword = (password) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
    return passwordPattern.test(password);
  };

  const displayOverlayMessage = (message, color = 'red') => {
    // Create the overlay message element
    const overlayMessage = document.createElement('div');
    overlayMessage.className = 'overlay-message';

    // Create the message content
    const messageContent = document.createElement('div');
    messageContent.className = 'message-content';

    // Create the message text
    const messageText = document.createElement('span');
    messageText.className = 'message-text';
    messageText.textContent = message;
    messageText.style.color = color;

    // Create the close button
    const closeButton = document.createElement('button');
    closeButton.className = 'close-button';
    closeButton.textContent = '×';
    closeButton.onclick = () => {
      overlayMessage.remove();
    };

    // Append the message text and close button to the message content
    messageContent.appendChild(messageText);
    messageContent.appendChild(closeButton);

    // Append the message content to the overlay message
    overlayMessage.appendChild(messageContent);

    // Append the overlay message to the document body
    document.body.appendChild(overlayMessage);

    clearModalValues();
  };

  const clearModalValues = () => {
    setCurrentPassword('');
    setNewPassword('');
    setPhoneNumber('');
  }

  const handleChangePassword = async (event) => {
    console.log('Change Password');
    console.log('Current Password:', currentPassword);
    console.log('New Password:', newPassword);
    if (currentPassword.trim() === '' || newPassword.trim() === '') {
      displayOverlayMessage('Enter Current and New Password');
      return
    }
    if (!(validatePassword(newPassword))) {
      displayOverlayMessage('Password must be at least 8 characters and have an uppercase, digit, and special character.');
      return
    }

    const request = new requestBody();
    request.uuid = userSession;
    request.password = currentPassword;
    request.newPassword = newPassword;
    request.account_action = "password_change"

    console.log("Sending uuid for password change", userSession);

    // Send a POST request to the accountaction API
    try {
      const response = await fetch(`${serverURL}/accountaction`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });
      if (!response.ok) {
        // Handle error response
        console.log("Got !response.ok")
        const errorMessage = await response.text();
        displayOverlayMessage(genericServerError);
        console.log(errorMessage);
      } else {
        // check password change
        const data = await response.json();
        console.log(data)
        if (data == "success") {
          displayOverlayMessage('Password changed successfully', 'green');
        } else if (data == "mismatch") {
          displayOverlayMessage('Wrong current password');
        } else if (data == "same") {
          displayOverlayMessage('Current and new password entered are same');
        } else {
          displayOverlayMessage(genericServerError);
        }
      }
    } catch (error) {
      displayOverlayMessage(genericServerError);
      console.error(error);
    }
    clearModalValues();
  };

  const handleSignOut = () => {
    console.log('Sign Out');
    clearSessionCookie();
    setDropdownOpen(false);
    navigate("/signin");
  };

  const handleAccountDelete = () => {
    console.log('Sign Out');
    clearSessionCookie();
    setDropdownOpen(false);
    navigate("/");
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?\d{11,20}$/;
    console.log(phoneNumber);
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleChangePhoneNumber = async (event) => {
    console.log('Change Phone Number');
    // Implement your logic for changing the phone number
    console.log('New Phone Number:', phoneNumber);
    if (!validatePhoneNumber(phoneNumber)) {
      displayOverlayMessage('Enter a valid phone number.');
      clearModalValues();
      return;
    }

    const request = new requestBody();
    request.uuid = userSession;
    request.phoneNumber = phoneNumber;
    request.account_action = "phonenumber_change"

    // Send a POST request to the accountaction API
    try {
      const response = await fetch(`${serverURL}/accountaction`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });
      if (!response.ok) {
        // Handle error response
        console.log("Got !response.ok")
        const errorMessage = await response.text();
        displayOverlayMessage(genericServerError);
        console.log(errorMessage);
      } else {
        // check phone number change
        const data = await response.json();
        console.log(data)
        if (data == "success") {
          displayOverlayMessage('Phone number changed successfully', 'green');
          setCurrphone(phoneNumber);
        } else {
          displayOverlayMessage(genericServerError);
        }
      }
    } catch (error) {
      displayOverlayMessage(genericServerError);
      console.error(error);
    }
    clearModalValues();
  };

  const handleDeleteAccount = () => {
    // Display the overlay message
    setIsDeletingAccount(true);
    const overlayMessage = document.createElement('div');
    overlayMessage.className = 'overlay-message';
    overlayMessage.innerHTML = `
      <div class="message-content">
        <span class="message-heading">Are you sure you want to delete the account?</span>
        <br />
        <br />
        <span class="message-text">This will delete all your data and you will no longer have access to SAGE365, the action is irreversible.</span>
        <div class="button-container">
          <button class="overlay-button" onclick="confirmDeleteAccount()">OK</button>
          <button class="overlay-button" onclick="removeOverlay()">Cancel</button>
        </div>
      </div>
    `;
    document.body.appendChild(overlayMessage);
  };

  window.confirmDeleteAccount = async (event) => {
    const request = new requestBody();
    request.uuid = userSession;
    request.account_action = "delete"

    // Send a POST request to the accountaction API
    try {
      const response = await fetch(`${serverURL}/accountaction`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });
      if (!response.ok) {
        // Handle error response
        console.log("Got !response.ok")
        const errorMessage = await response.text();
        displayOverlayMessage(genericServerError);
        console.log(errorMessage);
      } else {
        const data = await response.json();
        console.log(data)
        if (data != "success") {
          displayOverlayMessage(genericServerError);
        } else {
          console.log("Calling handleAccountDelete")
          closeModal();
          handleAccountDelete()
        }
      }
    } catch (error) {
      displayOverlayMessage(genericServerError);
      console.error(error);
    }
    closeModal();
  };

  window.removeOverlay = () => {
    setIsDeletingAccount(false);
    const overlay = document.querySelector('.overlay-message');
    if (overlay) {
      overlay.remove();
    }
  };

  return (
    showHeader && (
      <header className="header">
        {isDropdownOpen && (
          <div className="dropdown-overlay">
            <button className="overlay-menu-item" onClick={handleAccountClick}>
              Account
            </button>
            <button className="overlay-menu-item" onClick={handleSignOut}>
              Sign Out
            </button>
          </div>
        )}
        <div className="header-links">
          <a href="/home" className="header-link">
            Home
          </a>
          <a href="/dash" className="header-link">
            Dashboard
          </a>
          <a href="#" className="header-link">
            Pricing
          </a>
        </div>
        <div className="header-user-link">
          <img src={uLogo} alt="User" onClick={handleProfileClick} />
        </div>
        {/* Modal */}
        {showModal && (
          <div className="modal-overlay">
            <Modal isOpen={showModal} onClose={closeModal}>
              <h0>{email}</h0>
              <br />
              <br />
              <div className="modal-section">
                <h3>Change Password</h3>
                <div className="input-group">
                  <label htmlFor="currentPassword">Current Password</label>
                  <input
                    type="password"
                    id="currentPassword"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="modal-input"
                    disabled={isDeletingAccount}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="newPassword">New Password</label>
                  <input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="modal-input"
                    disabled={isDeletingAccount}
                  />
                </div>
                <button onClick={handleChangePassword} disabled={isDeletingAccount}>
                  Submit
                </button>
              </div>
              <div className="modal-section">
                <h3>Change Phone Number</h3>
                <p>{currphone}</p>
                <div className="input-group">
                  <div className="modal-input-container">
                    <div className="modal-phone-input">
                      <PhoneInput
                        value={phoneNumber}
                        onChange={(value) => setPhoneNumber(value)}
                        placeholder="Enter your phone number"
                        maxLength={20}
                        defaultCountry="US"
                        disabled={isDeletingAccount}
                      />
                    </div>
                  </div>
                </div>
                <button onClick={handleChangePhoneNumber} disabled={isDeletingAccount}>
                  Submit
                </button>
              </div>
              <button onClick={handleDeleteAccount} disabled={isDeletingAccount}>
                Delete Account
              </button>
            </Modal>
          </div>
        )}
      </header>
    )
  );
};

export default Header;