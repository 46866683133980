import React, { useState, useRef, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@material-ui/core';
import { GetApp, MoreVert, Delete } from '@material-ui/icons';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import './DisplayTable.css';
import { genericServerError, requestBody, serverURL, wsURL } from '../utils'
import { useSessionCookie } from '../../components/CookieChecker/CookieChecker';
import PopupOverlay from '../../components/PopupOverlay/PopupOverlay';


const FileTable = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [showOverlay, setShowOverlay] = useState(false);
    const [showitemOverlay, setShowitemOverlay] = useState(false);
    const [overlayPosition, setOverlayPosition] = useState({ top: 0, left: 0 });
    const [selectedItem, setselectedItem] = useState(null);
    const [files, setFiles] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreItems, sethasMoreItems] = useState(true);
    const { getSessionUUID } = useSessionCookie();
    const [onloadFetch, setonloadFetch] = useState(false);
    const [refreshEvent, setRefreshEvent] = useState(false);
    const [overlayText, setOverlayText] = useState('');
    const [overlayColor, setOverlayColor] = useState('');
    const [reconnectionAttempts, setReconnectionAttempts] = useState(0);

    let uniqueFileNames = new Set();

    const handleDownload = async (fileName) => {
        console.log(`Downloading file: ${fileName}`);
        try {
            // Logic to handle file download
            console.log(`Downloading file: ${fileName}`);
            const request = {
                uuid: getSessionUUID(),
                fileName: fileName,
            };

            if (!request.uuid) {
                console.log("No UUID, returning");
                return;
            }

            const response = await fetch(`${serverURL}/download`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            });

            if (response.ok) {
                // Create a blob from the response data
                const blob = await response.blob();

                // Create a temporary URL for the blob
                const fileUrl = URL.createObjectURL(blob);

                console.log("fileUrl", fileUrl)

                // Create a link element
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                link.download = fileName;

                // Append the link to the document body
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Remove the link from the document body
                document.body.removeChild(link);
            } else {
                setOverlayText("Failed to download file");
                setOverlayColor("red");
                setShowOverlay(true);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
            setOverlayText("Failed to download file");
            setOverlayColor("red");
            setShowOverlay(true);
        }
    };

    const handleSummarize = (fileId) => {
        // Logic to handle file summarization
        console.log(`Summarizing file with ID: ${fileId}`);
    };

    const clearOverlay = () => {
        setOverlayText("");
        setOverlayColor("");
        setShowOverlay(false);
    }

    const handleCloseOverlay = () => {
        clearOverlay();
    };

    const handleDelete = (fileId) => {
        // Logic to handle file deletion
        console.log(`Deleting file with ID: ${fileId}`);
    };

    const handleRowSelect = (event, fileId) => {
        const selectedIndex = selectedRows.indexOf(fileId);
        let updatedSelectedRows = [];

        if (selectedIndex === -1) {
            // fileId not in selectedRows, add it
            updatedSelectedRows = [...selectedRows, fileId];
        } else {
            // fileId already in selectedRows, remove it
            updatedSelectedRows = selectedRows.filter((id) => id !== fileId);
        }

        setSelectedRows(updatedSelectedRows);
    };

    const handleOverlayOpen = (event, fileId) => {
        console.log("Overlay OPEN");
        handleCloseOverlay();
        const boundingRect = event.currentTarget.getBoundingClientRect();
        setOverlayPosition({
            top: boundingRect.top + boundingRect.height,
            left: boundingRect.left,
        });
        setShowitemOverlay(true);
        setselectedItem(fileId);
    };

    const handleOverlayClose = () => {
        setShowitemOverlay(false);
        setselectedItem(null);
    };

    const overlayMouseLeave = () => {
        if (showitemOverlay) {
            handleOverlayClose();
        }
    };

    const handleSummarizeClick = () => {
        if (selectedItem) {
            handleSummarize(selectedItem);
            handleOverlayClose();
        }
    };

    const handleDeleteClick = () => {
        if (selectedItem) {
            handleDelete(selectedItem);
            handleOverlayClose();
        }
    };

    const handleRefresh = () => {
        // Trigger a refresh by incrementing the page number
        console.log("Calling handleRefresh");
        setPageNum(1);
        setIsLoading(false);
        sethasMoreItems(true);
        uniqueFileNames = new Set();
        setFiles([])
        setRefreshEvent(true);
        handleCloseOverlay();
    };

    useEffect(() => {
        console.log('Establishing WebSocket connection:');
        let socket;
        let reconnectTimeout;

        const handleWebSocketMessage = (event) => {
            // Handle incoming WebSocket messages
            const message = event.data;
            if (message === 'refresh') {
                // If the message is 'refresh', trigger the handleRefresh function
                handleRefresh();
            }
        };

        const handleWebSocketClose = (event) => {
            console.log('WebSocket connection closed:', event);

            // Reconnect after a delay
            const reconnectDelay = 2000; // 2 seconds
            reconnectTimeout = setTimeout(() => {
                if (reconnectionAttempts < 86400) {
                    console.log('Reconnecting...');
                    setReconnectionAttempts((prevAttempts) => prevAttempts + 1);
                    connectWebSocket();
                } else {
                    console.log('Exceeded maximum reconnection attempts.');
                    // Handle the case when maximum reconnection attempts are reached
                }
            }, reconnectDelay);
        };

        const connectWebSocket = () => {
            try {
                socket = new WebSocket(wsURL);

                socket.addEventListener('error', (error) => {
                    console.log('WebSocket listener for error:', error);
                });

                console.log('Websocket connected');

                socket.addEventListener('message', handleWebSocketMessage);
                socket.addEventListener('close', handleWebSocketClose);
            } catch (error) {
                console.error('Error with WebSocket:', error);
            }
        };

        connectWebSocket();

        return () => {
            console.error('Removing WebSocket listener:');
            clearTimeout(reconnectTimeout);
            socket.removeEventListener('message', handleWebSocketMessage);
            socket.removeEventListener('close', handleWebSocketClose);
            socket.close();
        };
    }, []);

    // const handleWebSocketMessage = (event) => {
    //     const message = event.data;
    //     if (message === 'refresh') {
    //         // If the message is 'refresh', trigger the handleRefresh function
    //         handleRefresh();
    //     }
    // };

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                setIsLoading(true);
                const request = new requestBody();
                const uuid_from_session = getSessionUUID();
                request.uuid = uuid_from_session;
                if (!request.uuid) {
                    console.log("No UUID, returning");
                    return;
                }
                console.log("Get items session", request.uuid, pageNum);
                const response = await fetch(`${serverURL}/get_items?page=${pageNum}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(request)
                });
                if (!response.ok) {
                    setIsLoading(false);
                    throw new Error("Failed to fetch items.");
                }
                const data = await response.json();

                if (data) {
                    console.log("Get items", request.uuid, data);
                    const num_of_items_received = data.length;

                    const uniqueFiles = data.filter((file) => {
                        if (!uniqueFileNames.has(file.fileName)) {
                            uniqueFileNames.add(file.fileName);
                            return true;
                        }
                        return false;
                    });

                    if (num_of_items_received < 10) {
                        // No more items to fetch
                        sethasMoreItems(false);
                        if (uniqueFiles.length != 0) {
                            setFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
                            setPageNum((prevPageNum) => prevPageNum + 1);
                        }
                    } else {
                        if (uniqueFiles.length != 0) {
                            setFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
                            setPageNum((prevPageNum) => prevPageNum + 1);
                        }
                    }
                    setIsLoading(false);
                } else {
                    console.log("No Data found")
                    sethasMoreItems(false);
                    setIsLoading(false);
                }

            } catch (error) {
                console.log('Error fetching files:', error);
                setIsLoading(false);
            }
        };

        if (!onloadFetch) {
            setonloadFetch(true)
            fetchFiles()
        }

        if (refreshEvent) {
            setRefreshEvent(false);
            console.log("refreshEvent true, fetching files")
            fetchFiles();
        }

        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading && hasMoreItems) {
                fetchFiles();
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pageNum, isLoading, hasMoreItems, refreshEvent]);

    const handleActions = async (action, fileName) => {
        if (action === "summarize") {
            // Handle the "Summarize" action
            try {
                const request = new requestBody();
                const uuid_from_session = getSessionUUID();
                request.uuid = uuid_from_session;
                request.fileName = selectedItem;
                if (!request.uuid) {
                    console.log("No UUID, returning")
                    return;
                }
                if (!request.fileName) {
                    console.log("No item name, returning")
                    return;
                }
                console.log("Get summary", request.uuid, request.fileName);
                const response = await fetch(`${serverURL}/get_summary`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(request)
                });
                if (!response.ok) {
                    throw new Error("Failed to get items summary.");
                }
                const data = await response.json();
                const values = Object.values(data);
                // Open new tab with summarized values
                const newTab = window.open("", "_blank");
                if (newTab) {
                    newTab.document.write(`
                        <html>
                            <head>
                            <style>
                                body {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                height: 100vh;
                                background-color: white;
                                font-family: Arial, sans-serif;
                                }
                                .line {
                                display: flex;
                                width: 100%;
                                margin-top: 10px;
                              }
                              
                              .line::after {
                                content: '';
                                flex-grow: 1;
                                border-top: 1px dotted #000;
                              }
                            </style>
                            </head>
                            <body>
                            <h1> Summary for ${selectedItem} </h1>
                            ${values.map((value, index) => `
                                <div>
                                <h2>Page ${index + 1}</h2>
                                <p>${value}</p>
                                <div class="line"></div>
                                </div>
                            `).join("")}
                            </body>
                        </html>
                        `);
                    newTab.document.close(); // Close the document
                } else {
                    console.error('Failed to open new tab.');
                    setOverlayText("Failed to open new tab, please check pop-up blocker");
                    setOverlayColor("red");
                    setShowOverlay(true);
                }
            } catch (error) {
                console.error('Error fetching items:', error);
            } finally {
            }
        } else if (action === "delete") {
            // Handle the "Delete" action
            console.log("Delete clicked for item:", selectedItem);
            // Handle the "Summarize" action
            try {
                const request = new requestBody();
                const uuid_from_session = getSessionUUID();
                request.uuid = uuid_from_session;
                request.fileName = selectedItem;
                if (!request.uuid) {
                    console.log("No UUID, returning")
                    return;
                }
                if (!request.fileName) {
                    console.log("No item name, returning")
                    return;
                }
                console.log("Delete request", request.uuid, request.fileName);
                setOverlayText("Delete request received");
                setOverlayColor("green");
                setShowOverlay(true);
                const response = await fetch(`${serverURL}/deletefile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(request)
                });
                if (!response.ok) {
                    setOverlayText(genericServerError)
                    setOverlayColor("red");
                    setShowOverlay(true);
                }
            } catch (error) {
                console.error('Error fetching items:', error);
            } finally {
            }
        }
        setselectedItem(null);
    };

    return (
        <div className="table-container" onMouseLeave={overlayMouseLeave} onClick={overlayMouseLeave}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell>File Name</TableCell>
                            <TableCell>File Upload Time</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files.map((file, index) => (
                            <TableRow key={`${file.id}-${index}`}>
                                <TableCell className="select-box">
                                    <Checkbox
                                        checked={selectedRows.includes(file.id)}
                                        onChange={(event) => handleRowSelect(event, file.id)}
                                    />
                                </TableCell>
                                <TableCell className="file-name-cell" data-file-name={file.fileName}>
                                    <a href="#" onClick={() => handleDownload(file.fileName)}>
                                        {file.fileName.length > 40 ? `${file.fileName.slice(0, 30)}...` : file.fileName}
                                    </a>
                                </TableCell>
                                <TableCell className="file-upload-time-cell">
                                    {file.uploadTime}
                                </TableCell>
                                <TableCell className="actions-cell">
                                    <div
                                        className="actions-container"
                                        onClick={(event) => handleOverlayOpen(event, file.fileName)}
                                    >
                                        <MoreVert className="action-icon" />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {showOverlay && (
                <PopupOverlay text={overlayText} overlayColor={overlayColor} onClose={handleCloseOverlay} />
            )}
            {showitemOverlay && selectedItem !== null && (
                <div
                    className="overlay"
                    style={overlayPosition}
                    onMouseLeave={overlayMouseLeave}
                >
                    <div className="overlay-item" onClick={() => handleActions("summarize")}>
                        Summarize
                    </div>
                    <div className="overlay-item" onClick={() => handleActions("delete")}>
                        Delete
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileTable;
