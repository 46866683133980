import React, { useState, useEffect } from 'react';
import './OTPVerify.css'
import PopupOverlay from '../../components/PopupOverlay/PopupOverlay';
import { requestBody, serverURL, wsURL, genericServerError } from '../utils'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const OTPVerify = ({}) => {
    const [code, setCode] = useState('');
    const [showOverlay, setShowOverlay] = useState(true);
    const [overlayText, setOverlayText] = useState('');
    const [overlayColor, setOverlayColor] = useState('');
    const location = useLocation();
    const email = location.state?.email;
    const navigate = useNavigate();

    const clearOverlay = () => {
        setOverlayText("");
        setOverlayColor("");
        setShowOverlay(false);
    }

    const handleCloseOverlay = () => {
        clearOverlay();
    };

    useEffect(() => {
        console.log("OTP verify", email)
        if (!email) {
            navigate("/home")
        }
    }, [email]);

    const handleCodeChange = (event) => {
        const inputValue = event.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9]/g, '');
        setCode(sanitizedValue);
    };

    const handleActivate = async (e) => {
        // Handle code activation logic here
        if (code.length < 6) {
            // Display error message for code length
            setOverlayText("Code must be 6 digits");
            setOverlayColor('red');
            setShowOverlay(true);
            return;
        }
        console.log('Activate button clicked');
        console.log('Code:', code);
        console.log('Email:', email);
        // Send a POST request to the verify OTP
        const request = new requestBody();
        request.otp = code;
        request.email = email
        try {
            const response = await fetch(`${serverURL}/verifyotp`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });
            if (!response.ok) {
                // Handle error response
                console.log("Got !response.ok")
                const errorMessage = await response.text();
                setOverlayText(genericServerError);
                setOverlayColor("red");
                setShowOverlay(true);
                console.log(errorMessage);
              } else {
                // check OTP verify
                const data = await response.json();
                console.log(data)
                if (data == "Yes") {
                    navigate("/signin", { state: { signin_text: "Signup successful, please login" } });
                } else if (data == "Expired") {
                    setOverlayText("OTP Expired");
                    setOverlayColor("red");
                    setShowOverlay(true);
                } else {
                    setOverlayText("OTP verification failed");
                    setOverlayColor("red");
                    setShowOverlay(true);
                }
              }
        } catch (error) {
            // Handle network or other errors
            console.log("verifyotp error path")
            console.error('Error:', error);
            setOverlayText(genericServerError);
            setOverlayColor("red");
            setShowOverlay(true);
        }
        setCode("");
    };

    const handleKeyDown = (event) => {
        clearOverlay();
        if (event.key === 'Enter') {
            handleActivate();
        }
    };

    const handleResend = async (e) => {
        // Logic to resend the code
        console.log("Resending code...");
        // Send a POST request to resend OTP
        const request = new requestBody();
        request.email = email;
        request.account_action = "updateotp"
        try {
            const response = await fetch(`${serverURL}/accountaction`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });
            if (!response.ok) {
                // Handle error response
                console.log("Got !response.ok")
                const errorMessage = await response.text();
                setOverlayText(genericServerError);
                setOverlayColor("red");
                setShowOverlay(true);
                console.log(errorMessage);
            } else {
                // check OTP verify
                const data = await response.json();
                console.log(data)
                if (data == "success") {
                    setCode("");
                    const text = "6-digit password reset code is sent to your email."
                    setOverlayText(text);
                    setOverlayColor('green');
                    setShowOverlay(true);
                } else {
                    setOverlayText(genericServerError);
                    setOverlayColor("red");
                    setShowOverlay(true);
                }
            }
        } catch (error) {
            // Handle network or other errors
            console.log("verifyotp error path")
            console.error('Error:', error);
            setOverlayText(genericServerError);
            setOverlayColor("red");
            setShowOverlay(true);
        }
        setCode("");
    }

    return (
        <div className="otpContainer">
            <p>Enter the 6-digit code sent to {email}</p>
            <input
                type="text"
                maxLength={6}
                value={code}
                onChange={handleCodeChange}
                onKeyDown={handleKeyDown}
                className="otpInput"
            />
            <button className="otpButton" onClick={handleActivate}>Activate</button>
            <p className="resendLink">Didn't receive the code or expired code, <a className="resendText" onClick={handleResend}>resend</a></p>
            {showOverlay && (
                <PopupOverlay text={overlayText} overlayColor={overlayColor} onClose={handleCloseOverlay} />
            )}
        </div>
    );
}

export default OTPVerify;