import React from 'react';
import Header from '../../components/Header/Header_home_dash';
import './AboutPage.css';
import Footer from '../../components/Footer/Footer';
import organize from './organize.png'
import search from './search.png'
import monetize from './monetize.png'

const AboutPage = () => {

  return (
    <div className="aboutpage-container">
      <Header ignoreSession="true" />
      <p className="aboutpage-mission-text">We're on a mission to empower you with full control over your data and unlock its full potential</p>
      <div className="aboutpage-organize-text">
        <div className="aboutpage-organize-description">
          <p className="aboutpage-organize-text-question">Have you ever found yourself struggling to keep track of all the valuable data you've stored over the years?</p>
          <p>It's common to have data scattered across multiple platforms and devices, from your local computer(s) to cloud storage services like Google Drive, Slack, Instagram, Dropbox, WhatsApp, Canva, Confluence, and many more.</p>
          <p>With SAGE365, you can finally bring order to the chaos. Our platform empowers you to seamlessly connect and manage all your data in one secure and convenient place.</p>
        </div>
        <img src={organize} alt="Organize" className="aboutpage-organize-image" />
      </div>
      <div className="aboutpage-search-text">
        <img src={search} alt="Search" className="aboutpage-search-image" />
        <div className="aboutpage-search-description">
          <p className="aboutpage-search-text-question">Have you ever found yourself struggling to recall specific information in your data or want to summarize a 50-page document?</p>
          <p>Whether you're looking for a quick summary of a lengthy document, searching for that chocolate cake recipe you created ages ago, seeking a family picture taken in front of the Eiffel tower years back, or trying to locate specific content in old slides before an important presentation, SAGE365 has got you covered.</p>
          <p>Our AI powered search capabilities and data indexing make it effortless to navigate through your data and find exactly what you're looking for, saving you time and frustration.</p>
        </div>
      </div>
      <div className="aboutpage-monetize-text">
        <div className="aboutpage-monetize-description">
          <p className="aboutpage-monetize-text-question">Have you ever wondered how you can easily monetize your valuable data and digital creations?</p>
          <p>SAGE365 Data Marketplace enables you to showcase and monetize your data like never before.</p>
          <p>With our platform, you have the power to advertise your data, set your own pricing, and unlock the potential value of your digital creations. Whether it's a unique dataset, an insightful report, a stunning visual artwork, or any other form of valuable digital content, our marketplace provides a platform for you to reach potential buyers and generate revenue.</p>
        </div>
        <img src={monetize} alt="Monetize" className="aboutpage-monetize-image" />
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
