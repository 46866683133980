class requestBody {
    constructor(email, password, newpassword, resetpassword, phoneNumber, firstName, lastName, uuid, fileName, query, otp, account_action) {
      this.email = email;
      this.password = password;
      this.newpassword = newpassword;
      this.resetpassword = resetpassword;
      this.phoneNumber = phoneNumber;
      this.firstName = firstName;
      this.lastName = lastName;
      this.uuid = uuid;
      this.fileName = fileName;
      this.query = query;
      this.otp = otp;
      this.account_action = account_action;
    }
};

// const wsURL = 'ws://localhost:8000/ws';
// const serverURL = 'http://localhost:8000';
const wsURL = 'wss://internal.isage365.com/ws';
const serverURL = 'https://internal.isage365.com';
const genericServerError = 'Server error, please try again later.';

export { requestBody, serverURL, wsURL, genericServerError };