import React, { useState } from 'react';
import './Sage.css';
import { Link } from 'react-router-dom';

const Sage = () => {
  const brightColors = [
    "#FF4500", // orange red
    "#FF00FF", // Magenta
    "#800080", // deep purple
    "#0000FF", // Blue
    "#008000", // Green
  ];

  return (
    <div className="sage">
      <div className="sage-text">
        <Link to="/" style={{ textDecoration: 'none' }}>
        {Array.from("SAGE 365").map((char, index) => (
        <span
        key={index}
        style={{
          color:
          brightColors[Math.floor(Math.random() * brightColors.length)],
        }}
        >
          {char}
          </span>
          ))}
          </Link>
      </div>
    </div>
  );
};

export default Sage;
