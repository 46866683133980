import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

export function useSessionCookie() {
  const [cookies, setCookie, removeCookie] = useCookies(['sage-user-session']);
  const navigate = useNavigate();

  const validateJWTAndGetUUID = (token) => {
    try {
      const decodedToken = jwt_decode(token);
      const uuid = decodedToken.uuid;
      return uuid;
    } catch (error) {
      console.error('Error decoding JWT:', error);
      return "";
    }
  };

  function setSessionCookie(componentOrString) {
    if (typeof componentOrString === 'string') {
      setCookie('sage-user-session', componentOrString, { secure: true, sameSite: 'strict' });
    }
  }

  function getSessionUUID() {
    const cookieValue = cookies['sage-user-session'];
    if (cookieValue) {
      //console.log("Session found {cookieValue}")
      const uuid = validateJWTAndGetUUID(cookieValue);
      // Check if UUID is valid
      if (uuid) {
        // Perform further actions with the UUID
        //console.log('Valid UUID:', uuid[0]);
        return(uuid[0]);
      } else {
        // Handle invalid JWT or decoding error
        //console.log('Invalid JWT');
        return("");
      }
    }
    return("");
  }

  function getSessionUUIDFromToken(token) {
    const cookieValue = token;
    if (cookieValue) {
      console.log("Session found {cookieValue}")
      const uuid = validateJWTAndGetUUID(cookieValue);
      // Check if UUID is valid
      if (uuid) {
        // Perform further actions with the UUID
        console.log('Valid UUID:', uuid[0]);
        return(uuid[0]);
      } else {
        // Handle invalid JWT or decoding error
        console.log('Invalid JWT');
        return("");
      }
    }
    return("");
  }

  function clearSessionCookie() {
    removeCookie('sage-user-session');
  }

  return { setSessionCookie, getSessionUUID, clearSessionCookie, getSessionUUIDFromToken};
};