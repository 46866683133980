import React, { useEffect } from 'react';
import FileTable from '../../components/DisplayTable/DisplayTable';
import SearchBar from '../../components/SearchBar/SearchBar';
import './DashboardPage.css';
import Footer from '../../components/Footer/Footer';
import Sage from '../../components/Sage/Sage';
import { useSessionCookie } from '../../components/CookieChecker/CookieChecker';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header_home_dash';



const DashboardPage = () => {
  const { getSessionUUID } = useSessionCookie();
  const navigate = useNavigate();

  useEffect(() => {
    const uuid_from_session = getSessionUUID();
    if (uuid_from_session === "") {
      console.log("No UUID found from session", uuid_from_session)
      navigate('/signin');
    } else {
      console.log("Found UUID from session", uuid_from_session)
    }
  }, [getSessionUUID, navigate]);

  return (
    <div className="page-container">
      <div className="solid-bar-frame">
        <div className="dash-header-container">
          <Header />
        </div>
        <div className="dash-sage-container">
          <Sage />
        </div>
        <div className="dash-search-container">
          <SearchBar />
        </div>
      </div>
      <div className="dashboard-frame">
        <FileTable />
      </div>
      <Footer />
    </div>
  );
};

export default DashboardPage;
