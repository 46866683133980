import React from 'react';
import './SearchResults.css';

function SearchResults({ results }) {
    return (
        <div className="searchresultcontainer">
            {results.length > 0 ? (
                results.map((entry, index) => {
                    const allPages = new Set(); // Set to collect unique pages

                    // Collect unique pages for the current item
                    Object.values(entry.raw_results).forEach((item) => {
                        const startPage = parseInt(item.start_page_num);
                        const endPage = parseInt(item.end_page_num);
                        allPages.add(startPage);
                        allPages.add(endPage);
                    });

                    const pageSummary = Array.from(allPages).sort((a, b) => a - b); // Sorted array of unique pages

                    return (
                        <div className="searchitem" key={index}>
                            <p>
                                <a href={entry.file_name}>{entry.file_name}</a>
                                &nbsp;&nbsp;[Pages Referenced: {pageSummary.join(', ')}]
                            </p>
                            <p>{entry.results}</p>
                        </div>
                    );
                })
            ) : (
                <div className="emptysearchitem">
                    <p>Nothing found, try search with a different query</p>
                </div>
            )}
        </div>
    );
}

export default SearchResults;