import React, { useState, useEffect } from 'react';
import './Home.css';
import SearchBar from '../../components/SearchBar/SearchBar';
import Footer from '../../components/Footer/Footer';
import { useSessionCookie } from '../../components/CookieChecker/CookieChecker';
import { useNavigate } from 'react-router-dom';
import organize from './organize.png'
import search from './search.png'
import monetize from './monetize.png'


const Carousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [organize, search, monetize];

  const getImageData = (index) => {
    // Define your logic to get the imageUrl based on the index
    let imageUrl = search;
    let title = ""
    if (index === 1) {
      if (currentImageIndex === 0) {
        imageUrl = organize;
        title = "Integrations are shown for informational purposes only, all integrations may not be supported"
      } else if (currentImageIndex === 1) {
        imageUrl = search;
        title = "File types shown for informational purposes only, all types may not be supported";
      } else {
        imageUrl = monetize;
        title = ""
      }
    }
    return { imageUrl, title };
  };

  useEffect(() => {
    const imageTimer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000);

    return () => {
      clearInterval(imageTimer);
    };
  }, [images.length]);

  return (
    <div className="carousel-container">
      <div className="carousel-images">
        {images.map((_, index) => {
          const { imageUrl, title } = getImageData(index);
          return (
            <img
              key={index}
              src={imageUrl}
              title={title}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === 1 ? 'active' : ''}`}
            />
          );
        })}
      </div>
      <div className="carousel-navigation">
        {images.map((_, index) => (
          <div
            key={index}
            className={`carousel-circle ${index === currentImageIndex ? 'active' : ''}`}
            onClick={() => setCurrentImageIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

const Home = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { getSessionUUID } = useSessionCookie();
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const brightColors = [
    "#FF4500", // orange red
    "#FF00FF", // Magenta
    "#800080", // deep purple 
    "#0000FF", // Blue
    "#008000", // Green
  ];

  useEffect(() => {
    const imageTimer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 10000);

    return () => {
      clearInterval(imageTimer);
    };
  }, []);


  useEffect(() => {
    const uuid_from_session = getSessionUUID();
    if (uuid_from_session === "") {
      console.log("No UUID found from session", uuid_from_session)
      navigate('/signin');
    } else {
      console.log("Found UUID from session", uuid_from_session)
    }
  }, [getSessionUUID, navigate]);


  const handleExploreClick = () => {
    setShowOverlay(prevState => {
      console.log('Previous State:', prevState);
      return !prevState;
    });
  };

  return (
    <div className="home">
      <div className="home-container">
        <Carousel />
        <div className="home-main-content">
          <div className="home-searchbar">
            <SearchBar />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};


export default Home;
