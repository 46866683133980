import React from 'react';
import './Signup.css';
import gLogo from './google_logo.png';
import aLogo from './apple_logo.png';
import mLogo from './microsoft_logo.png';
import Footer from '../../components/Footer/Footer';
import Sage from '../../components/Sage/Sage';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import PopupOverlay from '../../components/PopupOverlay/PopupOverlay';
import { requestBody, serverURL, wsURL } from  '../utils'

const Signup = () => {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(true);
  const [overlayText, setOverlayText] = useState('');
  const [overlayColor, setOverlayColor] = useState('');

  const clearOverlay = () => {
    console.log("Called clearOverlay")
    setOverlayText("");
    setOverlayColor("");
    setShowOverlay(false);
  }
  
  const handleCloseOverlay = () => {
    clearOverlay();
  };  

  const handleInputChange = (event) => {
    clearOverlay();
    setEmail(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleContinue();
    }
  };
  
  const handleContinue = async (event) => {
    if (email.trim() === "") {
      const text = "Please enter your email address.";
      setOverlayText(text);
      setOverlayColor('red'); 
      setShowOverlay(true);
      return;
    }
    if (!validateEmail(email)) {
      const text = "Please enter a valid email address.";
      setOverlayText(text);
      setOverlayColor('red'); 
      setShowOverlay(true);
      return;
    }

    const request = new requestBody(email);
    
    // Send a POST request to the signin API
    try {
      console.log("Server123 URL is", serverURL);
      const response = await fetch(`${serverURL}/check_emails_exists`, {
        method: 'POST',
        credentials: 'include', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });

      if (!response.ok) {
        // Handle error response
        const errorData = await response.json();
        const errorMessage = errorData.detail;
        console.log(errorMessage)
        const text = "Server error, please try later.";
        setOverlayText(text);
        setOverlayColor('red'); 
        setShowOverlay(true);
      } else {
        // call success
        const data = await response.json();
        const exists = data.exists;
        if (exists === "true") {
          console.log('Email exists');
          const text = `${email} already exists, sign-in or use a new email.`;
          setOverlayText(text);
          setOverlayColor('red'); 
          setShowOverlay(true);
          setEmail("");
        } else {
          navigate("/signup_pass", { state: { email } });
          setEmail("");
          clearOverlay();
        }
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error:', error);
      const text = "Server error, please try later.";
      setOverlayText(text);
      setOverlayColor('red'); 
      setShowOverlay(true);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <div className="signup">
      <Sage />
      <h2>Create your account</h2>
      <input 
        className="signup-text" 
        type="text" 
        placeholder="Enter your Email address"
        value={email}
        onChange={handleInputChange}
        onKeyDown={handleEnterKeyPress}
       />
      <button className="signup-continue-button" onClick={handleContinue}>
        Continue
      </button>
      <p className="signup-small-text">
        Already have an account? <a href="/signin">Log in</a>
      </p>
      <div className="signup-divider">
        <span className="signup-divider-line"></span>
        <span className="signup-divider-text">OR</span>
        <span className="signup-divider-line"></span>
      </div>
      <div className="signup-social-buttons">
        <button className="signup-social-button google">
          <img src={gLogo} alt="Google Logo" className="signup-logo" />
          Continue with Google
        </button>
        <button className="signup-social-button apple">
          <img src={aLogo} alt="Google Logo" className="signup-logo" />
          Continue with Apple
        </button>
        <button className="signup-social-button microsoft">
          <img src={mLogo} alt="Google Logo" className="signup-logo" />
          Continue with Microsoft
        </button>
      </div>
      {showOverlay && (
        <PopupOverlay text={overlayText} overlayColor={overlayColor} onClose={handleCloseOverlay} />
      )}
      <Footer />
    </div>
  );
};

export default Signup;
