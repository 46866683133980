import React from 'react';
import PasswordReset from '../../components/PasswordReset/PasswordReset';
import Sage from '../../components/Sage/Sage';
import Footer from '../../components/Footer/Footer';
import './PasswordResetPage.css'

const PasswordResetPage = () => {
  return (
    <div className="passwordreset-container">
      <div className="otpverify-container2">
      <div className="otpverifysage">
        <Sage />
      </div>
      <div className="passwordreset">
        <PasswordReset />
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordResetPage;