import React, { useState, useEffect } from 'react';
import './Signin.css';
import Footer from '../../components/Footer/Footer';
import Sage from '../../components/Sage/Sage';
import { useSessionCookie } from '../../components/CookieChecker/CookieChecker';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PopupOverlay from '../../components/PopupOverlay/PopupOverlay';
import { requestBody, serverURL, wsURL } from  '../utils'

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setSessionCookie } = useSessionCookie();
  const navigate = useNavigate();
  const location = useLocation();
  const signin_success = location.state?.signin_text;
  const [showOverlay, setShowOverlay] = useState(true);
  const [overlayText, setOverlayText] = useState('');
  const [overlayColor, setOverlayColor] = useState('');
  const { getSessionUUID } = useSessionCookie();

  const message = location.state?.message;

  console.log('Message from', message);

  useEffect(() => {
    if (signin_success) {
      setOverlayText(signin_success);
      setOverlayColor("green");
      setShowOverlay(true);
    } else if (message) {
      setOverlayText(message);
      setOverlayColor("green");
      setShowOverlay(true);
    }
  }, [signin_success, message]);

  useEffect(() => {
    const uuid_from_session = getSessionUUID();
    if (uuid_from_session === "") {
      console.log("No UUID found from session", uuid_from_session);
    } else {
      console.log("Found UUID from session", uuid_from_session);
      navigate('/home');
    }
  }, [getSessionUUID]);

  const clearOverlay = () => {
    setOverlayText("");
    setOverlayColor("");
    setShowOverlay(false);
  }

  const handleCloseOverlay = () => {
    clearOverlay();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    clearOverlay()
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    clearOverlay()
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLogin();
    }
  };

  const handleLogin = async (event) => {
    if (!email) {
      setOverlayText("Enter email");
      setOverlayColor("red");
      setShowOverlay(true);
      return;
    }

    if (!validateEmail(email)) {
      setOverlayText("Enter valid email");
      setOverlayColor("red");
      setShowOverlay(true);
      return;
    }

    if (!password) {
      setOverlayText("Enter password");
      setOverlayColor("red");
      setShowOverlay(true);
      return;
    }

    const request = new requestBody(email, password);
    
    // Send a POST request to the signin API
    try {
      const response = await fetch(`${serverURL}/signin`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });

      if (!response.ok) {
        // Handle error response
        console.log("Got !response.ok")
        const errorMessage = await response.text();
        setOverlayText('Invalid Credentials');
        setOverlayColor("red");
        setShowOverlay(true);
        console.log(errorMessage);
      } else {
        // Signin successful
        const data = await response.json();
        const token = data.token;
        console.log('Signin successful. Token:', token);
        clearOverlay()
        setSessionCookie(token);
        navigate("/home");
      }
    } catch (error) {
      // Handle network or other errors
      console.log("In Try error path")
      //console.error('Error:', error);
      setOverlayText("Server error, please try later");
      setOverlayColor("red");
      setShowOverlay(true);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="signin">
      <Sage />
      <input
        type="text"
        placeholder="Enter your email address"
        value={email}
        onChange={handleEmailChange}
        onKeyDown={handleKeyPress}
      />
      <input
        type="password"
        placeholder="Enter your password"
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={handleKeyPress}
      />
      <button className="signin-login-button" onClick={handleLogin}>
        Login
      </button>
      <p className="signin-small-text">
        <a href="/signup">New SAGE account</a>
      </p>
      <p className="signin-small-text2">
        <a href="/passreset">Forgot Password</a>
      </p>
      {showOverlay && (
        <PopupOverlay text={overlayText} overlayColor={overlayColor} onClose={handleCloseOverlay} />
      )}
      <Footer />
    </div>
  );
};

export default Signin;
