import React from 'react';
import Header from '../../components/Header/Header_home_dash';
import './PrivacyPage.css';
import Footer from '../../components/Footer/Footer';

const PrivacyPage = () => {
  return (
    <div className="privacypage-container">
      <Header ignoreSession="true" />
      <div className="privacy-container">
        <p className="privacy-heading">The Short Version</p>
        <p className="privacy-short-version">
          We believe that data privacy is a fundamental right. We don't sell, share, or use your information in any way that violates your right to privacy. Your data is your business and it belongs to <strong>Sage365</strong>.
          <br />
          <strong>Sage365</strong> is a <strong>"privacy first"</strong> product. It is designed with your privacy as the utmost priority. We empower you to take control of your data and ensure that it remains confidential and secure. With us, you can rest assured that your personal information will never be shared or sold to third parties without you enabling access. We firmly believe that your data belongs to you and only you. We do not engage in any invasive practices or data mining techniques that jeopardize your privacy.
          We provide robust security measures to safeguard your data. Our advanced encryption protocols and stringent data protection policies ensure that your information remains encrypted and inaccessible to anyone without your explicit permission.
          We understand the importance of trust and transparency when it comes to handling your data. That's why we adhere to strict privacy regulations and industry best practices to maintain the highest standards of data protection. Our commitment to privacy means that you have complete peace of mind knowing that your information is safe and remains under your control.
          Experience the freedom and peace of mind that comes with <strong>Sage365's</strong> privacy-focused platform.
        </p>
        <p className="privacy-heading">The Long Version</p>
        <p className="privacy-policy">
          <strong>Privacy Policy</strong>
          <br />
          <strong>Effective date:</strong> [7/5/2023]
          <br /><br />
          <strong>1. Ownership and Management of Data</strong>
          <br />
          You have full ownership and management control over the data you provide to Sage365. We do not claim any ownership rights to your data.
          <br /><br />
          <strong>2. Information Collection</strong>
          <br />
          We collect basic information about you to provide services, improve the quality of our offerings, and communicate with you. This information may include your name, email address, and other contact details.
          <br /><br />
          <strong>3. Collection of Location, Device, and Usage Information</strong>
          <br />
          To improve our services and user functionality, we may collect information related to your location, device, and usage patterns. This information is collected in an anonymized and aggregated form.
          <br /><br />
          <strong>4. Use of Cookies</strong>
          <br />
          We use cookies to provide a seamless login experience and personalize your interactions with our services. You can control the use of cookies through your browser settings.
          <br /><br />
          <strong>5. Information Sharing</strong>
          <br />
          We do not share your personal information with third parties, except when required by law or to comply with law enforcement requests.
          <br /><br />
          <strong>6. Data Retention</strong>
          <br />
          We retain your informationfor as long as you choose to use our services. When you delete your information, we will securely delete all associated data, except for basic account information that may be retained to enforce policies for future signups.
          <br /><br />
          <strong>7. Security</strong>
          <br />
          We take reasonable measures to safeguard your personal information and protect it against unauthorized access, loss, or misuse.
          <br /><br />
          <strong>8. International Visitors</strong>
          <br />
          Your data will be stored and processed in the United States. By using our services, you consent to the transfer of your data to the United States and other countries or regions as necessary for data storage and processing.
          <br /><br />
          <strong>9. Changes to this Privacy Policy</strong>
          <br />
          We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the revised version will be effective as of the date it is posted. If there are material changes to how we use or share personal information, we will notify you through our services, by email, or other communication.
          <br /><br />
          If you have any questions or concerns about our Privacy Policy, please contact us at admin@isage365.com.
        </p>
      </div>
      <Footer />
    </div>
  );
};


export default PrivacyPage;
