import React from 'react';
import Signup from '../../components/Signup/Signup';

const Signuppage = () => {
  return (
    <div>
      <Signup />
    </div>
  );
};

export default Signuppage;