import React, { useEffect } from "react";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import RemoteSources from "@uppy/remote-sources";
import DropTarget from "@uppy/drop-target";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

const UppyDashboard = () => {
  useEffect(() => {
    const uppyDashboard = new Uppy({ debug: true })
      .use(Dashboard, {
        inline: true,
        target: "#uppy-container",
        showProgressDetails: true,
        proudlyDisplayPoweredByUppy: false,
        showSelectedFiles: true,
        theme: "dark",
        note: "Supported files: .pdf, .docx, .txt"
      })
      .use(RemoteSources, {
        companionUrl: "http://companion.uppy.io",
        sources: [
        "GoogleDrive",
        "Dropbox",
        "OneDrive",
        "Box",
        "Url"
        ]
      })
      .use(DropTarget, {
        target: document.body
      })
    console.log("Upload successful");
    uppyDashboard.on("complete", (result) => {
      if (result.failed.length === 0) {
        console.log("Upload successful");
      } else {
        console.warn("Upload failed");
      }
      console.log("successful files:", result.successful);
      console.log("failed files:", result.failed);
    });

    return () => {
      uppyDashboard.close();
    };
  }, []);

  return <div id="uppy-container"></div>;
};

export default UppyDashboard;
