import React, { useEffect }  from 'react';
import { useNavigate } from 'react-router-dom';
import './Landing.css';
import { useSessionCookie } from '../../components/CookieChecker/CookieChecker';
import Footer from '../../components/Footer/Footer';

const Landing = () => {
  const { getSessionUUID } = useSessionCookie();

  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/signin');
  };

  const handleSignup = () => {
    navigate('/signup');
  };

  const brightColors = [
    "#FF4500", // orange red
    "#FF00FF", // Magenta
    "#800080", // deep purple
    "#0000FF", // Blue
    "#008000", // Green
  ];

  useEffect(() => {
    console.log("Getting session cookie in home");
    const uuid = getSessionUUID();
    if (uuid === "") {
      console.log("No UUID found", uuid)
    } else {
      console.log("Found UUID", uuid)
      navigate('/home');
    }
  }, [getSessionUUID]);

  return (
    <div className="landing">
        <div className="landing-text-content">
            <h1>
                Welcome to{" "}
                {Array.from("SAGE 365").map((char, index) => (
                <span
                key={index}
                style={{
                    color:
                    brightColors[Math.floor(Math.random() * brightColors.length)],
                }}
                >
                    {char}
                </span>
            ))}
            </h1>
        </div>
        <p>Log in with your SAGE AI account to continue</p>
        <div className="landing-buttons">
            <button className="landing-login-button" onClick={handleLogin}>
                Log in
            </button>
            <button className="landing-signup-button" onClick={handleSignup}>
              Signup
            </button>
        </div>
        <Footer />
    </div>
  );
};

export default Landing;
