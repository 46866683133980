import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <nav className="footer-nav">
        <a href="/about">About</a>
        <span class="separator">||</span>
        <a href="/">Home</a>
        <span class="separator">||</span>
        <a href="/privacy">Privacy</a>
        <span class="separator">||</span>
        <a href="/TOS">Terms of Service</a>
      </nav>
    </footer>
  );
};

export default Footer;