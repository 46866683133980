import React, { useState, useEffect } from 'react';
import './SearchBar.css';
import UppyDashboard from '../../components/Upload/Upload';
import PopupOverlay from '../../components/PopupOverlay/PopupOverlay';
import { useSessionCookie } from '../../components/CookieChecker/CookieChecker';
import { requestBody, serverURL, wsURL } from '../utils'
import { useNavigate } from 'react-router-dom';

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showUploader, setShowUploader] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);
  const [overlayText, setOverlayText] = useState('');
  const [overlayColor, setOverlayColor] = useState('');
  const [userSession, setUserSession] = useState('');
  const { getSessionUUID } = useSessionCookie();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const clearOverlay = () => {
    setOverlayText("");
    setOverlayColor("");
    setShowOverlay(false);
  }

  const handleCloseOverlay = () => {
    clearOverlay();
  };

  const handleSearch = async (e) => {
    if (!searchTerm) {
      return;
    }
    console.log("Search text is", searchTerm)
    const request = new requestBody();
    request.uuid = userSession;
    request.query = searchTerm
    console.log("Sending uuid", userSession, searchTerm);
    setLoading(true);

    // Send a POST request to the search API
    try {
      const response = await fetch(`${serverURL}/search`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });
      const data = await response.json();
      // Process the response from the backend API
      console.log(data);
      navigate('/searchresults', { state: { results: data } });
    } catch (error) {
      console.error(error);
    }
    setSearchTerm("")
    setLoading(false)
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    clearOverlay();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
    clearOverlay();
  };

  const handleUpload = () => {
    setShowUploader(true);
    clearOverlay();
  };

  const handleCloseUploader = () => {
    setShowUploader(false);
    clearOverlay();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
    clearOverlay();
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  useEffect(() => {
    const uuid_from_session = getSessionUUID();
    if (uuid_from_session === "") {
      console.log("No UUID found from session", uuid_from_session);
      navigate('/signin');
    } else {
      console.log("Found UUID from session", uuid_from_session);
      setUserSession(uuid_from_session);
    }
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      // Code to be executed after the specified delay
      console.log('Timer callback');
    }, 1000);

    // Return a cleanup function to stop the timer when the component unmounts
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const handleDrop = async (e) => {
    let file_upload_count = 0
    const filesWithError = [];
    clearOverlay();
    e.preventDefault();
    setIsDragOver(false);
    const files = Array.from(e.dataTransfer.files);
    console.log('Files dropped:', files);
    // Iterate over each dropped file and send it to the backend "upload" API
    const request = new requestBody();
    request.uuid = userSession;
    console.log("Sending uuid", userSession);
    for (const file of files) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("request", JSON.stringify(request));
      try {
        const response = await fetch(`${serverURL}/upload`, {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        // Process the response from the backend API
        console.log(data);
        if (data == "success") {
          file_upload_count = file_upload_count + 1;
        } else {
          filesWithError.push(file.name);
        }
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error(error);
        filesWithError.push(file.name);
      }
    }

    let text = ""
    if (file_upload_count > 0) {
      if (filesWithError.length >= 1) {
        text = `Success uploading ${file_upload_count} file${file_upload_count !== 1 ? 's' : ''}.\nError uploading ${filesWithError.join(', ')}`;
      } else {
        text = `Success uploading ${file_upload_count} file${file_upload_count !== 1 ? 's' : ''}.\n Processing may take few seconds`;
      }
      setOverlayText(text);
      setOverlayColor('green');
      setShowOverlay(true);
      // Set a timer for 10 seconds
      setTimeout(() => {
        clearOverlay();
      }, 10000);
    } else {
      text = `Error uploading ${filesWithError.join(', ')}`;
      setOverlayText(text);
      setOverlayColor('red');
      setShowOverlay(true);
    }
  };

  return (
    <div
      className={`search-bar ${isDragOver ? 'drag-over' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="search-bar-input">
        <input
          type="text"
          value={searchTerm}
          disabled={loading}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          placeholder="Search your content or drag and drop files to explore more..."
          className={isDragOver ? 'drag-over-input' : ''}
        />
      </div>
      <div className="search-bar-buttons">
        <button className="search-bar-button" onClick={handleSearch} disabled={loading}>
          {loading ? (
            <div className="loading-container">
              <div className="spinner"></div>
              <span className="loading-text">Search</span>
            </div>
          ) : (
            'Search'
          )}
        </button>
        <button className="search-bar-button" onClick={handleUpload} disabled={loading}>
          Upload
        </button>
      </div>
      {showUploader && (
        <div className="uploader-container">
          <div className="uploader-header">
            <button className="close-button" onClick={handleCloseUploader}>
              X
            </button>
          </div>
          <UppyDashboard />
        </div>
      )}
      {showOverlay && (
        <PopupOverlay text={overlayText} overlayColor={overlayColor} onClose={handleCloseOverlay} />
      )}
    </div>
  );
};

export default SearchBar;
