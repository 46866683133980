import React from 'react';
import Home from '../../components/Home/Home';
import Header from '../../components/Header/Header_home_dash';


const Homepage = () => {
  return (
    <div>
      <Header />
      <Home />
    </div>
  );
};

export default Homepage;