import { useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from '../../components/Footer/Footer';
import Sage from '../../components/Sage/Sage';
import './Signup_pass.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';
import PopupOverlay from '../../components/PopupOverlay/PopupOverlay';
import { requestBody, serverURL, wsURL } from '../utils'
import { useSessionCookie } from '../../components/CookieChecker/CookieChecker';

const SignupPass = () => {
    const { clearSessionCookie } = useSessionCookie();
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [password, setPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const location = useLocation();
    const { email } = location.state;
    const navigate = useNavigate();
    const [showOverlay, setShowOverlay] = useState(true);
    const [overlayText, setOverlayText] = useState('');
    const [overlayColor, setOverlayColor] = useState('');
    const [isAgreedToTOS, setIsAgreedToTOS] = useState(false);

    const clearOverlay = () => {
        setOverlayText("");
        setOverlayColor("");
        setShowOverlay(false);
    }

    const handleCloseOverlay = () => {
        clearOverlay();
    };

    const validatePassword = (password) => {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
        return passwordPattern.test(password);
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberPattern = /^\+?\d{11,20}$/;
        console.log(phoneNumber);
        return phoneNumberPattern.test(phoneNumber);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        clearOverlay();
    };

    const handlePhoneNumberChange = (value) => {
        setPhoneNumber(value);
        clearOverlay();
    };

    const handlefirstNameChange = (event) => {
        setfirstName(event.target.value);
        clearOverlay();
    };

    const handlelastNameChange = (event) => {
        setlastName(event.target.value);
    };

    const handleEnterKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSignup(event);
        }
    };

    const handleSignup = async (event) => {
        let validateStr = "";

        if (firstName === "") {
            validateStr = "First name, "
        }

        if (password === "") {
            validateStr = validateStr + "Password, ";
        }

        if (phoneNumber === "") {
            validateStr = validateStr + "Phone number ";
        }

        if (validateStr) {
            validateStr = validateStr + "is a required field"
            setOverlayText(validateStr);
            setOverlayColor('red');
            setShowOverlay(true);
            console.log(password, firstName, phoneNumber);
            return;
        }

        let checkFieldStr = "";

        if (!validatePassword(password)) {
            checkFieldStr = "Password must be at least 8 characters and have an uppercase, digit, and special character.<br>"
        }

        if (!validatePhoneNumber(phoneNumber)) {
            checkFieldStr = checkFieldStr + "Enter a valid phone number."
        }

        if (checkFieldStr) {
            setOverlayText(checkFieldStr);
            setOverlayColor('red');
            setShowOverlay(true);
            return;
        }

        if (!isAgreedToTOS) { // Check if the checkbox is not checked
            setOverlayText("Please agree to the Terms of Service");
            setOverlayColor('red');
            setShowOverlay(true);
            return;
        }

        console.log(firstName, lastName, email, password, phoneNumber);
        // Create a request body object

        const request = new requestBody();
        request.email = email;
        request.password = password;
        request.phoneNumber = phoneNumber;
        request.firstName = firstName;
        request.lastName = lastName;

        console.log("insert", email, password, phoneNumber, firstName, lastName);

        try {
            // Send a POST request to the signup API
            const response = await fetch(`${serverURL}/signup`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

            if (!response.ok) {
                // Handle error response
                const errorData = await response.json();
                const errorMessage = errorData.detail;
                setOverlayText(errorMessage);
                setOverlayColor('red');
                setShowOverlay(true);
                console.log(errorMessage);
            } else {
                // Signup successful
                // Reset form fields
                clearSessionCookie();
                setfirstName('');
                setlastName('');
                setPassword('');
                setPhoneNumber('');
                clearOverlay();
                console.log("Signup suucessful", email);
                navigate("/otpverify", { state: { email } });
            }
        } catch (error) {
            // Handle network or other errors
            console.error('Error:', error);
            setOverlayText("Server error, please try again later.");
            setOverlayColor('red');
            setShowOverlay(true);
        }
    };

    return (
        <div className="signup-pass">
            <Sage />
            <div className="signup-pass-textbox">
                <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={handlefirstNameChange}
                    onKeyDown={handleEnterKeyPress}
                    maxLength={40}
                />
            </div>
            <div className="signup-pass-textbox">
                <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={handlelastNameChange}
                    onKeyDown={handleEnterKeyPress}
                    maxLength={40}
                />
            </div>
            <div className="signup-pass-textbox">
                <input
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyDown={handleEnterKeyPress}
                    maxLength={128}
                />
            </div>
            <div className="phone-input-wrapper">
                <PhoneInput
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="Enter your phone number"
                    onKeyDown={handleEnterKeyPress}
                    maxLength={20}
                    defaultCountry="US"
                />
            </div>
            <p className="signup-pass-small-text">
                Phone verification is required to verify your identity. Your number will only be used for security purposes.
            </p>
            <div className="signup-pass-checkbox">
                <input
                    type="checkbox"
                    checked={isAgreedToTOS}
                    onChange={(e) => setIsAgreedToTOS(e.target.checked)}
                />
                <label>
                    I agree to the&nbsp;
                    <a
                        href="/TOS"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Service
                    </a>
                </label>
            </div>
            <button className="signup-pass_button" onClick={handleSignup}>
                Signup
            </button>
            {showOverlay && (
                <PopupOverlay text={overlayText} overlayColor={overlayColor} onClose={handleCloseOverlay} />
            )}
            <Footer />
        </div>
    );
};

export default SignupPass;