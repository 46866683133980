import './TOSPage.css'
import React from 'react';
import Header from '../../components/Header/Header_home_dash';
import Footer from '../../components/Footer/Footer';

const TOSPage = () => {
  return (
    <div className="TOSpage-container">
      <Header ignoreSession="true" />
      <div className="TOS-container">
        <h1><strong>SAGE 365 Terms of Service</strong></h1>

        <p>
          Last updated: [7/5/2023]
        </p>

        <p>
          Please read these <strong>Terms of Service</strong> ("Terms") carefully before using the SAGE 365 cloud service ("Service") operated by SAGE 365 ("<strong>SAGE 365</strong>", "<strong>we</strong>", "<strong>us</strong>", or "<strong>our</strong>"). These Terms govern your access to and use of the Service. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the Service.
        </p>

        <h2><strong>1. Description of Service</strong></h2>

        <p>
          SAGE 365 provides users with the ability to upload, search, summarize, and share their content through the Service. The Service is hosted on cloud infrastructure and maintains complete privacy, utilizing user content solely for the purpose of providing services to the user for their own content.
        </p>

        <h2><strong>2. Bugs, Interruptions, and Unavailability</strong></h2>

        <p>
          The Service may experience bugs, interruptions, or unavailability from time to time. Under no circumstances shall SAGE 365 be held liable for any damages or losses arising from such occurrences. We strive to provide a reliable and uninterrupted Service, but we cannot guarantee its continuous operation.
        </p>

        <h2><strong>3. Payment Processing</strong></h2>

        <p>
          Payment processing for subscription plans or any other transactions made through the Service is handled by third-party payment processors. SAGE 365 is not responsible for any errors, issues, or disputes related to payment processing. Users are required to comply with the terms and conditions of the respective payment processors.
        </p>

        <h2><strong>4. Account</strong></h2>

        <p>
          You are solely responsible for maintaining the confidentiality of your account, including your password. You agree to notify SAGE 365 immediately of any unauthorized use of your account or any other breach of security.
        </p>

        <h2><strong>5. User Content</strong></h2>

        <p>
          You acknowledge that user-generated content is the sole responsibility of the user. You must ensure that your content belongs to you and that you have the necessary rights, licenses, or permissions to use and share the content. SAGE 365 does not claim ownership of user-generated content.
        </p>

        <h2><strong>6. Ownership and Proprietary Rights</strong></h2>

        <p>
          SAGE 365 retains ownership of all intellectual property rights related to the Service, including but not limited to software, logos, trademarks, and copyrights. You may not use or reproduce any SAGE 365 intellectual property without prior written consent.
        </p>

        <h2><strong>7. User Conduct</strong></h2>

        <p>
          You agree not to use the Service for any illegal or unauthorized purpose or in violationof any local, state, national, or international laws or codes. You are solely responsible for your conduct while using the Service and agree to comply with all applicable laws and regulations.

        </p>

        <h2><strong>8. Loss of User Data</strong></h2>

        <p>
          SAGE 365 makes no guarantee regarding the loss of user data due to bugs, issues, infrastructure failures, or any other unforeseen circumstances. It is your responsibility to regularly back up your data and take necessary precautions to prevent data loss.
        </p>

        <h2><strong>9. Data Security</strong></h2>

        <p>
          SAGE 365 takes reasonable measures to maintain the security of user data. However, in the event of a data breach or unauthorized access, SAGE 365 cannot be held liable. By using the Service, you acknowledge and accept the inherent risks associated with data transmission over the internet.
        </p>

        <h2><strong>10. Modifications to the Service and Terms</strong></h2>

        <p>
        SAGE 365 reserves the right to modify, suspend, or discontinue the Service at any time without prior notice. We may also update these Terms from time to time. Any changes to the Terms will be effective immediately upon posting the updated version on the SAGE 365 website or notifying you through the Service. Your continued use of the Service after any modifications to the Terms shall constitute your acceptance of the modified Terms. It is your responsibility to review the Terms periodically to stay informed about any updates or changes.
        </p>

        <h2><strong>11. Indemnity</strong></h2>

        <p>
        You agree to indemnify and hold harmless SAGE 365 and its officers, directors, employees, and agents from any claims, damages, losses, liabilities, costs, or expenses (including reasonable attorneys' fees) arising out of or related to your use of the Service, violation of these Terms, or infringement of any rights of a third party.
        </p>

        <h2><strong>12. Limitation of Liability</strong></h2>

        <p>
        To the maximum extent permitted by applicable law, SAGE 365 shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of the Service.
        </p>

        <h2><strong>13. Governing Law</strong></h2>

        <p>
        These Terms of Service shall be governed by and construed in accordance with the laws of the state of California, United States, without regard to its conflict of law principles. Any legal actions or proceedings arising out of or relating to these Terms shall be exclusively subject to the jurisdiction of the courts located in California.
        </p>

        <h2><strong>14. Severability</strong></h2>

        <p>
        If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.
        </p>

        <h2><strong>15. Entire Agreement</strong></h2>

        <p>
        These Terms constitute the entire agreement between SAGE 365 and you regarding the Service and supersede any prior agreements or understandings.
        </p>


        <h2><strong>16. Contact Us</strong></h2>

        <p>
          If you have any questions or concerns about these Terms or the Service, please contact us at admin@isage365.com
        </p>

        <p>
          <strong>---</strong>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TOSPage;
