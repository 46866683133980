import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import HomePage from './pages/HomePage/HomePage';
import LegalPage from './pages/LegalPage/LegalPage';
import LandingPage from './pages/Landing/Landing';
import PreLandingPage from './pages/Landing/PreLanding';
import SignupPage from './pages/SignupPage/SignupPage';
import SigninPage from './pages/SigninPage/SigninPage';
import SignupPass from './components/Signup/Signup_pass';
import AboutPage from './pages/AboutPage/AboutPage';
import PrivacyPage from './pages/PrivacyPage/PrivacyPage';
import SearchResultsPage from './pages/SearchResultsPage/SearchResultsPage';
import OTPVerifyPage from './pages/OTPVerifyPage/OTPVerifyPage';
import PasswordResetPage from './pages/PasswordResetPage/PasswordResetPage';
import TOSPage from './pages/TOSPage/TOSPage';

import './App.css';

const App = () => {
  return (
  <Router>
    <div>
      <Routes>
        <Route path="/" element={<PreLandingPage />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/dash" element={<DashboardPage />} />
        <Route path="/legal" element={<LegalPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/signup_pass" element={<SignupPass />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/searchresults" element={<SearchResultsPage />} />
        <Route path="/otpverify" element={<OTPVerifyPage />} />
        <Route path="/passreset" element={<PasswordResetPage />} />
        <Route path="/tos" element={<TOSPage />} />
      </Routes>
    </div>
  </Router>
);
};

export default App;