import React from 'react';

const LegalPage = () => {
  return (
    <div>
      <h2>Legal Information</h2>
      <p>This is the legal page content.</p>
    </div>
  );
};

export default LegalPage;
