import React from 'react';
import OTPVerify from '../../components/OTPVerify/OTPVerify';
import Sage from '../../components/Sage/Sage';
import Footer from '../../components/Footer/Footer';
import './OTPVerifyPage.css'

const OTPVerifyPage = () => {
  return (
    <div className="otpverify-container">
      <div className="otpverify-container2">
        <div className="otpverifysage">
          <Sage />
        </div>
        <div className="OTPverify">
          <OTPVerify />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OTPVerifyPage;