import React from 'react';
import './PopupOverlay.css';

const PopupOverlay = ({ text, overlayColor, onClose }) => {
  if (!text) {
    return null;
  }

  let displayText = text;
  if (displayText.length > 60) {
    displayText = displayText.slice(0, 60) + '...';
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>X</button>
        <p
          className="overlay-text"
          title={text}
          style={{ color: overlayColor }}
        >
          {displayText}
        </p>
      </div>
    </div>
  );
};

export default PopupOverlay;
