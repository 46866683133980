import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './PreLanding.css';
import { useSessionCookie } from '../../components/CookieChecker/CookieChecker';
import Footer from '../../components/Footer/Footer';
import PopupOverlay from '../../components/PopupOverlay/PopupOverlay';


const PreLanding = () => {
  const { getSessionUUID } = useSessionCookie();
  const [invitationCode, setInvitationCode] = useState(["", "", "", "", "", ""]);
  const [showOverlay, setShowOverlay] = useState(true);
  const [overlayText, setOverlayText] = useState('');
  const [overlayColor, setOverlayColor] = useState('');
  const validCodes = ["248632", "654321", "987654"];

  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    // Filter out non-digit characters using regular expression
    const filteredValue = value.replace(/\D/g, "");
    setInvitationCode((prevCode) => {
      const newCode = [...prevCode];
      newCode[index] = filteredValue;
      return newCode;
    });
  
    // Jump to the next input field if a digit is entered
    if (filteredValue.length === 1 && index < invitationCode.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const clearOverlay = () => {
    setOverlayText("");
    setOverlayColor("");
    setShowOverlay(false);
  }

  const handleCloseOverlay = () => {
    clearOverlay();
  };

  const handleKeyDown = (e, index) => {
    clearOverlay()
    if (e.key === "Enter" && index < 5) {
      setOverlayText("Invitation code is 6-digits");
      setOverlayColor("red");
      setShowOverlay(true);
    }
    if (e.key === "Enter" && index == 5) {
      inputRefs.current[0].focus();
      handleSubmit()
    }
  };

  const handleSubmit = () => {
    const code = invitationCode.join("");
    if (validCodes.includes(code)) {
      // Code is valid, perform the desired action
      console.log("Valid code:", code);
      // Reset the input fields
      setInvitationCode(["", "", "", "", "", ""]);
      handleSignup()
    } else {
      // Code is invalid, handle the error or display a message
      console.log("Invalid code:", code);
      const error_str = "Invalid code: " + code
      setOverlayText(error_str);
      setOverlayColor("red");
      setShowOverlay(true);
      setInvitationCode(["", "", "", "", "", ""]);
    }
  };


  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/signin');
  };

  const handleSignup = () => {
    navigate('/signup');
  };

  const brightColors = [
    "#FF4500", // orange red
    "#FF00FF", // Magenta
    "#800080", // deep purple
    "#0000FF", // Blue
    "#008000", // Green
  ];

  useEffect(() => {
    console.log("Getting session cookie in home");
    const uuid = getSessionUUID();
    if (uuid === "") {
      console.log("No UUID found", uuid)
    } else {
      console.log("Found UUID", uuid)
      navigate('/home');
    }
  }, [getSessionUUID]);

  return (
    <div className="prelanding">
      <div className="prelanding-text-content">
        <h1>
          Welcome to{" "}
          {Array.from("SAGE 365").map((char, index) => (
            <span
              key={index}
              style={{
                color:
                  brightColors[Math.floor(Math.random() * brightColors.length)],
              }}
            >
              {char}
            </span>
          ))}
        </h1>
      </div>
      <p>Enter your six-digit invitation code:</p>
      <div className="digit-boxes">
        {invitationCode.map((value, index) => (
          <input
            key={index}
            ref={(ref) => (inputRefs.current[index] = ref)}
            type="text"
            maxLength={1}
            value={value}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        ))}
      </div>
      <prelanding-button onClick={handleSubmit}>Submit</prelanding-button>
      {showOverlay && (
        <PopupOverlay text={overlayText} overlayColor={overlayColor} onClose={handleCloseOverlay} />
      )}
      <Footer />
    </div>
  );
};

export default PreLanding;